import { Typography } from '@mui/material';
type AuthFormHeaderProps = {
  title: string;
  subtitle: string;
};
export const AuthFormHeader = ({
  title,
  subtitle
}: AuthFormHeaderProps) => <>
    <Typography variant="h3" sx={{
    textAlign: 'center'
  }} data-sentry-element="Typography" data-sentry-source-file="AuthFormHeader.tsx">
      {title}
    </Typography>
    <Typography sx={{
    mt: 3,
    mb: 4,
    mx: 'auto',
    textAlign: 'center',
    maxWidth: {
      sm: '90%'
    }
  }} dangerouslySetInnerHTML={{
    __html: subtitle
  }} data-sentry-element="Typography" data-sentry-source-file="AuthFormHeader.tsx" />
  </>;